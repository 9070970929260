import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import type { MetricsData } from "~/components/metric";
import type { DebtDisplay } from "~/server/model/debtDisplay";
import type { ExpenseDisplay } from "~/server/model/expenseDisplay";
import type { IncomeDisplay } from "~/server/model/incomeDisplay";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatDate(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (1 + date.getMonth()).toString().padStart(2, '0'); // Months are zero-based in JavaScript
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export function formatDateString(input: string): string {
    // Parse the input string to a Date object
    const date = new Date(input);

    // Format the date as a string in the 'yyyy-mm-dd' format
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function toMetricsData(data: DebtDisplay[] | ExpenseDisplay[] | IncomeDisplay[], dateRange: string) {
    // Calculate the start date based on the dateRange
    const now = new Date();
    const startDate = new Date(now);
    const endDate = new Date();
    let listLength = 0;

    if (dateRange === 'week') {
        startDate.setDate(now.getDate() - 6);
        listLength = 7;
    } else if (dateRange === 'month') {
        startDate.setDate(1);
        listLength = endDate.getDate() - startDate.getDate() + 1;
    } else {
        startDate.setDate(1);
        startDate.setMonth(0);
        startDate.setFullYear(2024); // Assuming you want to start from the beginning of 2024
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Get today's date without time
        listLength = Math.ceil((today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    }

    const groupedData: Record<string, MetricsData> = {};

    data.forEach((d) => {
        const dateKey = formatDate(d.date)
        // Check if the data is of type IncomeDisplay[] and d.is_received is true
        if (data[0]?.hasOwnProperty("is_received")) {
            // Add amount only if is_received is true
            if (!(d as IncomeDisplay).is_received) return;

            if (!groupedData[dateKey]) {
                groupedData[dateKey] = {
                    date: dateKey,
                    total: d.amount
                };
            } else {
                (groupedData[dateKey]!).total += d.amount;
            }
        } else {
            // For other types of data or when is_received is false, sum amounts as usual
            if (!groupedData[dateKey]) {
                groupedData[dateKey] = {
                    date: dateKey,
                    total: d.amount
                };
            } else {
                (groupedData[dateKey]!).total += d.amount;
            }
        }
    });

    const mappedData: MetricsData[] = Object.values(groupedData);
    const sortedData = mappedData.sort((a, b) => {
        const dateA = new Date(a.date.split('-').reverse().join('-'));
        const dateB = new Date(b.date.split('-').reverse().join('-'));
        return dateA.getTime() - dateB.getTime();
    });

    const zeroFilledArray = Array(listLength).fill(null).map((_, i) => {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const formattedDate = formatDate(date);
        return {
            date: formattedDate,
            total: 0
        };
    });

    const mergedData = zeroFilledArray.map(zeroFilledEntry => {
        const existingEntry = sortedData.find(entry => entry.date === zeroFilledEntry.date);
        return existingEntry ? existingEntry : zeroFilledEntry;
    });

    return mergedData;
}

export const expenseCategory = [
    {
        id: 1,
        name: '🍲 Food',
        value: 'food'
    },
    {
        id: 2,
        name: '🥤 Beverage',
        value: 'beverage'
    },
    {
        id: 3,
        name: '☕ Coffee',
        value: 'coffee'
    },
    {
        id: 4,
        name: '🎬 Entertainment',
        value: 'entertainment'
    },
    {
        id: 5,
        name: '💊 Health',
        value: 'health'
    },
    {
        id: 6,
        name: '📚 Education',
        value: 'education'
    },
    {
        id: 7,
        name: '⛽️ Gas',
        value: 'gas'
    },
    {
        id: 8,
        name: '🚌 Transportation',
        value: 'transportation'
    },
    {
        id: 9,
        name: '💸 Others',
        value: 'others'
    }
];

export const debtCategory = [
    {
        id: 1,
        name: '🎒 Personal',
        value: 'personal_loan'
    },
    {
        id: 2,
        name: '💼 Professional',
        value: 'professional_loan'
    },
    {
        id: 3,
        name: '💰 Others',
        value: 'others'
    }
];

export const incomeCategory = [
    {
        id: 1,
        name: '💵 Salary',
        value: 'salary'
    },
    {
        id: 2,
        name: '🤑 Bonus',
        value: 'bonus'
    },
    {
        id: 3,
        name: 'Reimburse',
        value: 'reimburse'
    },
    {
        id: 4,
        name: '👨‍👩‍👦 Parents',
        value: 'parents'
    },
    {
        id: 5,
        name: '💵 Others',
        value: 'others'
    }
]

export function formatAmount(num: number | undefined): string {
    if (num == undefined) return '0';
    // Create a formatter for the 'de-DE' locale
    const formatter = new Intl.NumberFormat('de-DE');
    // Format the number
    return formatter.format(num);
}
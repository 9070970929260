"use client";

import { cn } from "~/lib/utils";
const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

import LottieAmateurSpender from './amateur-spender.json'
import dynamic from "next/dynamic";

export default function LottieLoading({ className }: { className?: string }) {
    return (
        <div className={cn(className)}>
            <Lottie animationData={LottieAmateurSpender} />
        </div>
    )
}
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/src/app/PosthogPageView.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/src/components/lottie/lottie-loading.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/src/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/amateur-spender/amateur-spender/src/styles/globals.css")